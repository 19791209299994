import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ImageText from "../elements/imagetext.tsx";
import Title from "../elements/title.tsx";
import Gap from "../elements/gap.tsx";
import AliceCarousel from "react-alice-carousel";
import Annotation from "../elements/annotation.tsx";
import TextLayout from "../elements/textlayout.tsx";
import PhotoGallery from "../elements/gallery.tsx";
import "react-alice-carousel/lib/alice-carousel.css";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TextLayout mdxType="TextLayout">
  <h4>Do you see what i see</h4>
  <h5>
    An interactive light installation of different perspectives on neighbourhood
    heritage
  </h5>
  <br />
  2024 <br />
  Organisation: <a href="https://nexumdata4art.com/">Nexum Data4Art </a>
  <br />
  Exhibition: <a href="https://v2.nl/"> V2_ Lab for the Unstable Media</a>,
  Rotterdam
  <br />
  Keywords: generative art, lithophane, neighbourhood, light, data4art
  <iframe width="100%" height="315" src="https://www.youtube.com/embed/UQDxVaIy5jI?si=UJyUkgYEZb2cALcE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
    </TextLayout>
    <PhotoGallery photos={[{
      src: "../../../doyousee/demo5.jpeg",
      width: 4,
      height: 1
    }, {
      src: "../../../doyousee/demo1.jpeg",
      width: 2,
      height: 1
    }, {
      src: "../../../doyousee/demo2.jpeg",
      width: 2,
      height: 1
    }, {
      src: "../../../doyousee/demo4.jpeg",
      width: 2,
      height: 1
    }, {
      src: "../../../doyousee/demo3.jpeg",
      width: 2,
      height: 1
    }]} mdxType="PhotoGallery" />
    <TextLayout mdxType="TextLayout">
  <b>Introduction</b>
  <Text mdxType="Text">
    The project collaborates with researcher Lidwine Spoormans, translating corresponding research data into an interactive light installation. The research [Spoormans et al., 2023] discusses how individuals and groups identify attributes as their heritage, which is crucial for anticipating potential conflicts or shared interests in renovation processes. The project aims to make valuable research more accessible to a broader audience through art, reducing the knowledge gap on how stakeholders, individuals and groups, convey significance to their living environment, in their similarities and differences.
    <br /> <br />
    The essence of the concept lies in experiencing the neighbourhood scene through various perspectives. Audiences are invited to interact, observe, and tangibly experience the variation of perspectives between and within stakeholder groups and their influences on the neighbourhood. It also involves overlaying different lenses to showcase diverse visual effects, symbolizing the contributions per stakeholder profile and their collective impact on the assessment of heritage.
    <br /> <br />
    The original research was conducted in Amsterdam Zuidoost. The artist then applied insights from this research to their own neighborhood - Lombok, Utrecht, which inspired the imaginative neighborhood scene. The imagination is brought to life by AI and the illustration was transformed into lithophanes, where the neighbourhood's data is depicted through varying light luminance and their respective locations.
        <img src="./../../doyousee/Lombok1.jpg" />
        <img src="./../../doyousee/Lombok2.jpg" />
        <Annotation mdxType="Annotation">
  Applying research insights from Amsterdam Zuidoost to Utrecht Lombok
        </Annotation>
        <img src="./../../doyousee/A1.png" />
        <img src="./../../doyousee/A5.png" />
        <Annotation mdxType="Annotation">
  AI generated images, inspired by Lombok, Utrecht
        </Annotation>
        <img src="./../../doyousee/elements2.jpg" />
        <Annotation mdxType="Annotation">
  Each element and their corresponding neighbourhood heritage attribute
  representations
        </Annotation>
        <img src="./../../doyousee/data1.jpg" />
        <img src="./../../doyousee/data2.jpg" />
        <Annotation mdxType="Annotation">Neighbourhood data [Spoormans et al., 2023]</Annotation>
  </Text>
    </TextLayout>
    <Gap margin={100} mdxType="Gap" />
    <hr />
    <h5>{`References:`}</h5>
    <div>
  Spoormans, L., Czischke, D., Pereira Roders, A., & de Jonge, W. (2023). “Do I
  See What You See?”—Differentiation of Stakeholders in Assessing Heritage
  Significance of Neighbourhood Attributes. Land, 12(3), Article 712.
  https://doi.org/10.3390/land12030712
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      